<template>
<div id="ContactService">  
  <Header/>
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfuncup="onFuncUp"/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          聯絡客服
        </a>
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
    <!-- End header -->
    <div class="container mt-3">
      <div class="text-right" data-bs-toggle="modal" data-bs-target="#notice1">
        <img src="assets/img/img035.png" class="avatar  avatar-xs m-2">
      </div>
    </div>
    <div class="container">
      <table class="table table-sm table-borderless align-items-center mb-0 ">
        <tbody>
          <!-- 名稱 -->
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img001.png" class="avatar avatar-xs me-2">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-smm text-simbold">名稱</h6>
                </div>
              </div>
            </td>
            <td class="align-middle text-right ">
              <input type="text" class="form-control input-group-text" maxlength="10" autocomplete="off" v-model="name"> 
            </td>
          </tr>
          <!-- 手機號碼 -->
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/ic_table_phone_18.png" class="avatar avatar-xs me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-smm">手機號碼</h6>
                </div>
              </div>
            </td>
            <td class="align-middle text-right">
             <input type="number" class="form-control input-group-text " oninput="if(value.length>10)value=value.slice(0,10)" autocomplete="off" v-model="phone" v-if="!hasPhone">
             <input type="number" class="form-control input-group-text " :value="phone" disabled v-else>
           </td>
          </tr> 
          <!-- 電子郵件 -->
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img003.png" class="avatar avatar-xs me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-smm">電子郵件</h6>
                </div>
              </div>
            </td>
            <td class=" text-right text-wrap w-85 ">
              <input type="text" class="form-control input-group-text" v-model="email"> 
            </td>
          </tr>
          <!-- 問題類別 -->
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img004.png" class="avatar avatar-xs me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-smm">問題類別</h6>
                </div>
              </div>
            </td>
            <!-- <td class="align-middle text-right" data-bs-toggle="modal" data-bs-target="#notice3"> -->
            <td class="align-middle text-right" @click="showModal('#notice3')">
              <!-- eslint-disable vue/no-v-html -->
              <h6 class="mb-0 text-smm text-dark"> {{ feedbackTypeName }} &nbsp;
                <i class="far fa-chevron-right text-muted "></i>
              </h6>
              <!--eslint-enable-->
            </td>
          </tr>
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img005.png" class="avatar avatar-xs me-2 rounded-0 nicolecontain ">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-smm">聯絡內容</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-group mt-md-2 mb-md-4">
        <textarea class="form-control bg-light border-0  rounded-top input_textarea" :class="(pic1 != '') ? ' rounded-0' : 'rounded-bottom' " rows="3"  placeholder="輸入聯絡內容，我們會盡快回覆您：）" v-model="text"> </textarea>
        <div class="d-flex justify-content-center bg-gray py-2 px-3 rounded-0 rounded-bottom" :class="(pic1 == '') ? 'hide' : '' ">
          <div class="position-relative me-4" v-show="pic1 != ''">
            <img :src="'data:image/png;base64,' + pic1" class="avatar avatar-nicole-lg">
            <img src="assets/img/ic_photo_delete.png" class="x" @click="deletePic(1)">
          </div>
          <div class="position-relative me-4" v-show="pic2 != ''">
            <img :src="'data:image/png;base64,' + pic2" class="avatar avatar-nicole-lg">
            <img src="assets/img/ic_photo_delete.png" class="x" @click="deletePic(2)">
          </div>        
          <div class="position-relative " v-show="pic3 != ''">
            <img :src="'data:image/png;base64,' + pic3" class="avatar avatar-nicole-lg">
            <img src="assets/img/ic_photo_delete.png" class="x" @click="deletePic(3)">
          </div>
        </div>
      </div>
      <div class="d-flex align-content-center  mt-3">
        <img class="icon-xs mt-1" :src="(pic3 != '') ? 'assets/img/ic_question_photo_black.png' : 'assets/img/ic_question_photo_green.png'">
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="pic3 != ''">
          <h6 class="mb-0 text-smm" v-html="'&nbsp;上傳圖片讓我們更了解你的問題'"> </h6>
        </div>
        <div v-else>
          <h6 class="mb-0 text-smm text-success" @click="clickAlbumButton()" v-html="'&nbsp;上傳圖片讓我們更了解你的問題'"> </h6>
        </div>
        <!--eslint-enable-->
      </div>
      <div class="mt-4 mt-md-5 text-center">
        <h6 class="text-orange text-sm">LINE線上客服： @easyreading</h6>
        <h6 class="text-yellow text-sm text-simbold">服務時間:周一到周五，早上9:30-下午6:00</h6>
      </div>

      <div class="d-flex align-content-center justify-content-between  mt-5">
        <div class="w-50 text-center" >
          <div @click="sendQuestion">
            <button type="button" class="btn btn-lg  btn-outline-info rounded-pill w-85 text-s mx-2" :class="(canSubmit) ? '' : 'disabled'" data-bs-dismiss="modal" >送出</button>
          </div>
        </div> 
        <div class="w-50 text-center">
          <button type="button" class="btn btn-lg  btn-success rounded-pill w-85 text-s mx-2 " data-bs-dismiss="modal" @click="openQuestionRecordList()">查看回覆</button> 
        </div>   
      </div>
    </div>

  <!-- footer -->
    <div class="nicolespace"></div>
    <div class="nicolespace"></div>
    <nav class="navbar  navbar-light bg-white z-index-3  fixed-bottom py-1">
      <div class="col-12 px-0">
        <div class="d-flex">
          <div class="col-3 text-center">
            <img src="assets/img/ic_tab_home_unselected.png" class="avatar avatar-nicole-sm2"> 
              <h6 class="mb-0 text-muted small text-simbold">首頁</h6>
          </div>       
          <div class="col-3 text-center">
            <img src="assets/img/ic_tab_dailyessay_unselected.png" class="avatar avatar-nicole-sm2"> 
              <h6 class="mb-0 text-muted small text-simbold">每日學習</h6>
          </div>        
          <div class="col-3 text-center">
            <img src="assets/img/ic_tab_course_unselected.png" class="avatar avatar-nicole-sm2"> 
              <h6 class="mb-0 text-muted small text-simbold">深度學習</h6>
          </div>       
          <div class="col-3 text-center">
            <img src="assets/img/ic_tab_me.png" class="avatar avatar-nicole-sm2"> 
              <h6 class="mb-0 text-muted small text-simbold text-success">我的</h6>
          </div>
        </div>
      </div>
    </nav>
  <!-- End footer -->


  <!-- Modal -->
    <div class="modal " id="notice3" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
      <div class="modal-sm-big  modaldown  modal-dialog modal-dialog-down" role="document">
        <div class="modal-content rounded-bottom-0">
          <div class="modal-header pt-3 pb-2 border-bottom">
            <h4 class="text-center text-n fw-bold text-dark mb-0 ">選擇類別</h4>
          </div>  
          <div class="mx-3 mb-1 text-center" v-for='(feedbackType, index) in feedbackTypeList' v-bind:key='feedbackType.ftid'>
            <h5 class="text-s text-dark my-3" data-bs-dismiss="modal" v-on:click='onClickFeedbackType(index)'> {{ feedbackType.name }} </h5>
          </div>
        </div>
      </div>
    </div> 
  <!--End Modal -->  

  </body>
  <Footer/>
</div>
</template>

<script>
import swal from "sweetalert2";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CommonAlert1 from '@/components/CommonAlert1.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

export default {
  name: 'ContactService',
  components: {
      Header,
      Footer,
      CommonAlert1
  },
  mounted(){
    window.openAlbumCallback = this.openAlbumCallback;
  },
  data () {
    return { 
      // api setting
      setDebugMode:true ,
      apiDebug: false,
      apiName: "config_get_feedback_type",
      perPage: 3,  //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      fun: "",
      act: "",
      filterData: {},
      form:{
        seq: 10,
        switch: true,
      },


      // data
      isSelectImage: false,
      isDoubleClick: false,
      name: "",
      phone: "",
      email: "",
      ftid: "", 
      text: "",
      hufid: "",
      back:"",

      canSubmit: false,
      pic1: "",
      pic2: "",
      pic3: "",

      feedbackTypeList:{},
      feedbackTypeName: "類別",
      friendUid: "",
      friendShareCode: "",


      // alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,

      hasPhone: false,
    }
  },
  methods: {
    openLogin(){
      this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"ContactService", type:""}), this.openLoginCallback);
    },
    openLoginCallback(){

    },
    sendQuestion(){
      if (this.isDoubleClick) {
        return;
      }
      this.isDoubleClick = true;
      // Date.now() 返回毫秒
      if ((Date.now() - this.$GlobalData.contact_service_add_contact_time) < (1*1000)) {
        this.title = "";
        this.content = "距離前次發送未達一分鐘，請於【發問紀錄>>聯絡客服】，確認是否已成功發送，謝謝。";
        this.btnNameUp = "確定";
        this.$refs.commonAlert1.onShow();
        this.isDoubleClick = false;
        return;
      }
      if (this.name == "" || this.phone == "" || this.email == "" || this.ftid == "" || this.text == "" || this.name == null || this.phone == null || this.email == null|| this.ftid == null|| this.text == null) {
        this.title = "";
        this.content = "請先填寫完整資料";
        this.btnNameUp = "我知道了";
        this.$refs.commonAlert1.onShow();
        this.isDoubleClick = false;
        return;
      }

      // 修改用戶的個人訊息
      this.apiName="member_put_user/"+this.$GlobalData.uid;
      this.form["name"] = this.name;
      this.form["phone"] = this.phone;
      this.form["email"] = this.email;
      this.fun = "ContactService";
      this.act = "put";
      oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
        if (res != undefined && res.error != undefined) {
          this.title = "錯誤";
          this.content = res.error;
          this.btnNameUp = "確定";
          this.$refs.commonAlert1.onShow();
          this.isDoubleClick = false;
          return;
        } 
        // 新增聯絡客服的紀錄
        this.apiName="history_post_history_user_feedback";
        this.act = "post";
        this.form["uid"] = this.$GlobalData.uid;
        this.form["text"] = this.text;
        this.form["ftid"] = this.ftid;
        oAC.postRecord(oAC.getApiParam(this), {}).then((res)=> {
          if (res != undefined && res.error != undefined){
            this.title = "錯誤";
            this.content = res.error;
            this.btnNameUp = "確定";
            this.$refs.commonAlert1.onShow();
            this.isDoubleClick = false;
            return;
          }
          if (res != undefined && res.history_user_feedback != undefined && res.history_user_feedback["hufid"] != undefined) {
            this.hufid = res.history_user_feedback["hufid"];
          }

          this.$GlobalData.contact_service_add_contact_time = Date.now();
        
          // 新增圖片
          if (this.hufid != "") {
            // 新增聯絡客服發問的圖片
            this.apiName="page_contact_service_post_image";
            this.act = "post";
            this.form["uid"] = this.$GlobalData.uid; 
            this.form["hufid"] = this.hufid; 
          
            if (this.pic1 == "") {
              this.form["file"] = this.pic1;
            } else if (this.pic2 == "") {
              this.form["file2"] = this.pic2;
            } else if (this.pic3 == "") {
              this.form["file3"] = this.pic3;
            }
        
            let json = oAC.getApiParam(this);
            
            this.apiName="page_contact_service_post_image"; 
            json.hufid = this.hufid;
            json.uid = this.$GlobalData.uid; 
            
            var file = this.pic1;
            var file2 = this.pic2;
            var file3 = this.pic3;
          
            oAC.postFileRecord3(json, file, file2, file3).then(res => {
              this.getUserData();
            }).catch((err)=> { 
              oltConsole.log(err);
              this.title = "修改失敗";
              this.content = "請確認圖片" ;
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
              this.isDoubleClick = false;

            });
          } else {
            this.getUserData();
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      }).catch((err)=> {
        oltConsole.log(err);
      });

      this.isDoubleClick = false;
    },
    sendQuestionCallback(){
    },
    clickAlbumButton(){
      oltConsole.log("clickAlbumButton");
      this.$JsBridge.callHandler('openAlbumVue', JSON.stringify({backUrl:"ContactService", type:""}), this.openAlbumCallback );
    },
    openAlbumCallback(res){
      if (res != null && res != undefined) {
        if (this.pic1 == "") {
          this.pic1 = res;
        } else if (this.pic2 == "") {
          this.pic2 = res;
        } else if (this.pic3 == "") {
          this.pic3 = res;
        }
      }
    },
    onClickFeedbackType(index){
      if (this.feedbackTypeList.length > 0 && index < this.feedbackTypeList.length) {
        this.feedbackTypeName = this.feedbackTypeList[index].name;
        this.ftid = this.feedbackTypeList[index].ftid;
      }
    },
    openQuestionRecordList(){
      this.$router.push({
        name: "QuestionRecordList",
        params: {
          back: "ContactService",
          openMode: "Feedback"
        }
      });
    },
    setOnBack(){
      this.$JsBridge.callHandler('setOnback', '', this.onBack);
    },
    onBack(){
      $("#notice3").modal("hide");
      if (this.back != "" && this.back == "RecommendFriend") {
        this.$router.push({
          name: this.back,
          query:{
            display: "ReceiveDisplay"
          }
        });
      } else if (this.back != "") {
        this.$router.push({
          name: this.back,
        });
      } else {
        this.$router.push({
          name: "Mine"
        });
      }
    },
    getFeedbackType(){
      this.apiName="config_get_feedback_type";
      this.filterData["sort"] = "seq";
      this.filterData["sortby"] = "ASC";
      oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {
        if(res != null && res.feedback_type != null && res.feedback_type.length > 0){
          this.feedbackTypeList = res.feedback_type;
          if (this.$route.query.text != undefined && this.$route.query.text != "") {
            for (var i = 0; i < this.feedbackTypeList.length; i++) {
              if (this.feedbackTypeList[i].ftid == 5) {
                this.onClickFeedbackType(i);
              }
            }
          }
        }
      }).catch((err)=> {
        oltConsole.log("error6" + err);
      });
    },
    getUser(){
      if (this.$GlobalData.uid == "") { return; }
      this.apiName="member_get_user";
      this.filterData['uid'] = this.$GlobalData.uid;
      this.filterData['switch'] = 'Y';
      oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {
        if (res != undefined && res.user != null && res.user.length > 0) {
          if (res.user[0].name != "") {
            this.name = res.user[0].name;
          }
          if (res.user[0].phone != "" && res.user[0].phone != null) {
            this.hasPhone = true;
            this.phone = res.user[0].phone;
          }
          if (res.user[0].email != "") {
            this.email = res.user[0].email;
          }
        }
      }).catch((err)=> {
        oltConsole.log("error7" + err);
      });
    },
    deletePic(index){
      switch(index){
        case 1:
          this.pic1 = this.pic2;
          this.pic2 = this.pic3;
          this.pic3 = "";
        break;
        case 2:
          this.pic2 = this.pic3;
          this.pic3 = "";
        break;
        case 3:
          this.pic3 = "";
        break;
      }
    },
    uploadPic(index){
      let file = "";
      let checkNextPic = "";
      switch(index){
        case 1: file = this.pic1; checkNextPic = 2; break;
        case 2: file = this.pic2; checkNextPic = 3;break;
        case 3: file = this.pic3; checkNextPic = "";break;
      }
      if (file == "") {
        this.getUserData();
      } else {
        let json = oAC.getApiParam(this);
        json.sub_dir = 'history_user_feedback';
        json.hufid = this.hufid;
        oAC.postFileRecord(json, file).then(res => {
          if (checkNextPic == "") {
            this.getUserData();
          } else {
            this.uploadPic(checkNextPic);
          }
        }).catch((err)=> {
          oltConsole.log(err);
          this.title = "修改失敗";
          this.content = "請確認圖片" + index;
          this.btnNameUp = "確定";
          this.$refs.commonAlert1.onShow();
          this.isDoubleClick = false;
          this.isDoubleClick = false;
        });
      }
    },
    getUserData(){
      this.apiName="member_get_user_data";
      oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid} ).then(res => {
        if (res != undefined && res.record != null && res.record.length > 0 && res.rt != null && res.rt.user != null && res.rt.user.length > 0 ) {
          this.setUserData(res);
        }
      }).catch((err)=> {
        oltConsole.log(err);
          this.isDoubleClick = false;

      });
    },
    setUserData(res){
      var json = {
        record: res.record,
        rt: res.rt,
        token: this.$GlobalData.token
      };
      this.$GlobalData.user = res.rt.user;
      this.$JsBridge.callHandler('setUserData',JSON.stringify( json ), this.setUserDataCallback);		  
    },
    setUserDataCallback(){
      this.putHistoryUsedShareCode();
      this.messageAlert();
      this.isDoubleClick = false;
    },
    messageAlert(){
      var json = {
        title: "",
        message: "已送出訊息!"
      };
      this.$JsBridge.callHandler('messageAlertVue',JSON.stringify( json ), this.messageAlertVueCallback);
      this.ftid = "";
      this.text = "";
      this.pic1 = "";
      this.pic2 = "";
      this.pic3 = "";
      this.feedbackTypeName = "類別";
    },
    messageAlertVueCallback(){},
    putHistoryUsedShareCode(){
	
      if (this.ftid == 5) {	  
        this.apiName="history_post_history_used_share_code_update?uid="+this.$GlobalData.uid;
        this.fun = "ContactService";
        this.act = "post";		
        oAC.postRecord(oAC.getApiParam(this), {}).then((res)=> {
        }).catch((err)=> {
          oltConsole.log(err);
        });
      }
    },
    showModal(target){
      $(target).modal("show");
    },
    JumpToTop(){
      // 自動將畫面移動到最上方
      $("html, body").animate({ scrollTop: $("html").offset().top }, {duration: 500,easing: "swing"});
    }
  },
  created(){
    this.JumpToTop();
    if (this.$route.query.uid != undefined) {
      this.$GlobalData.uid = this.$route.query.uid;
    }
    if (this.$route.query.back != undefined) {
      this.back = this.$route.query.back;
    } else if (this.$route.params.back != undefined) {
      this.back = this.$route.params.back;
    }
    if (this.$JsBridge.isAndroid) {
      this.setOnBack();
    }
    // oltConsole.log("uid: " + this.$GlobalData.uid);
    this.getFeedbackType();
    this.getUser();
    this.text = this.$route.query.text;
    this.friendUid = this.$route.query.friendUid;
    this.friendShareCode = this.$route.query.friendShareCode;
	
	
  },
  updated(){ 
    // 檢查是否可以送出發問 
    if (this.name == "" || this.phone == "" || this.email == "" || this.ftid == "" || this.text == "" || this.name == null || this.phone == null || this.email == null|| this.ftid == null|| this.text == null) {
      this.canSubmit = false;
    } else {
      this.canSubmit = true;
    }
  }
};
</script>
<style type="text/css">
  .input_color{
    border: transparent !important;
  }
  /*隱藏文字區塊的縮放三角形*/
  .input_textarea{
    resize: none;
    min-height: 32vw !important;
  }
  /*隱藏輸入數字的上下箭頭*/
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .hide{
    display:none !important;
  }

  input:focus{outline:none}
</style>

<style scoped>
  /deep/ .disabled{
    color: #c9c9c9 !important;
  }
  /deep/ .form-control:disabled{
    background-color:unset;
  }
</style>